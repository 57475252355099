
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import Dummy from "../../assets/image/user-circle.svg";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
function CreateNotification() {
    const [block, setBlock] = useState(false);
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [userList, setUserList] = useState([]);
    const [topic, setTopic] = useState("");
    const [description, setDescription] = useState("");
    const [selectedUser, setSelectedUser] = useState([]);
    const [image, setImage] = useState();
    const [selectedOption, setSelectedOption] = useState("allUsers");
    const [userIds, setUserIds] = useState([]);
    console.log("selectedUser---", selectedUser);
    console.log("userIds---", userIds);
  const nagivate=useNavigate()
    function openModal() {
      setModalIsOpen(true);
    }
    function closeModal() {
      setModalIsOpen(false);
    }
    const imageValidation = (file) => {
        if (!file) return;
        console.log("file", file);
        // const allowFileType = ["jpeg", "jpg", "png"];
        // const getFileType = file.type.split("/").pop();
        // if (allowFileType.includes(getFileType)) {
        if (file) {
          setImage(file);
        } else {
          setImage("");
        //   toast.error("Image type not valid!");
        }
      };
  
    
  
    return (
        <>

            <div>
                <Row style={{ justifyContent: "end" }}>
                  
                    <Col xl={1} className="text-end">
                        <div
                            className="bg-gray-200 hover:bg-gray-600 text-black outline-1  rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                            onClick={() => {nagivate("/notification-management")
                            }}
                            id="navigatortool"
                        >
                            Cancel
                        </div>
                    </Col>
                    <Col xl={2} className="text-end">
                        <div
                            className="bg-gray-600 hover:bg-gray-800 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                            onClick={() => {nagivate("/notification-management")
                            }}
                            id="navigatortool"
                        >
                            Send
                        </div>
                    </Col>
                </Row>

            </div>
           <div>
        <div class="item-wrapper one">
          <div class="item">
            <form>
              <div class="item-inner">
                <div class="item-content">
                  <div class="image-upload">
                    <label style={{ cursor: "pointer" }} for="file_upload">
                      <img src="" alt="" class="uploaded-image" />

                      <div class="h-100">
                        <div class="dplay-tbl">
                          <div class="dplay-tbl-cell">
                            <i class="fa fa-cloud-upload"></i>
                            <h5>
                              <b>Upload Image</b>
                            </h5>
                            {/* <h6 class="mt-10 mb-70">Or Drop Your Image Here</h6> */}
                          </div>
                        </div>
                      </div>
                      <input
                        data-required="image"
                        type="file"
                        name="image_name"
                        id="file_upload"
                        class="image-input"
                        data-traget-resolution="image_resolution"
                        value=""
                        onChange={(e) => imageValidation(e.target.files[0])}
                      />
                    </label>
                  </div>
                  <div style={{ textAlign: "center", marginTop: "14px" }}>
                    {image ? image.name : ""}
                  </div>
                </div>
              </div>
            </form>

            <div className="notification-user-button">
              <button
                className={`button1 ${
                  selectedOption == "allUsers" ? "active" : ""
                }`}
              >
                All User
              </button>
              <button
                className={`button2 ${
                  selectedOption == "selectedUsers" ? "active" : ""
                }`}
                onClick={openModal}
              >
                Select User
              </button>
            </div>

            <Modal
              isOpen={modalIsOpen}
              //onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              //style={customStyles}
              contentLabel="Example Modal"
              style={{
                overlay: {
                  //borderRadius: "10px",
                  backgroundColor: "hwb(0deg 16.86% 83.14% / 83%)",
                  zIndex: "1000",
                  // top:"50%",
                  // left:"50%",
                  // marginTop:"-300px",
                  // marginLeft:"-350px"
                },
                content: {
                  overflow: "hidden",
                  width: 379,
                  height: 396,
                  marginTop: 0,
                  backgroundColor: "#FFFFFF 0% 0 % no - repeat padding- box",
                  boxShadow: "0px 44px 89px #0052CC26",
                  borderRadius: "26px",
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                },
              }}
            >
              <div className="position-relative text-center">
                <h4 className="modal-change-password-text">Select User</h4>
                <img
                //   src={close}
                  onClick={closeModal}
                  className="position-absolute top-0 end-0"
                  alt="close"
                />
              </div>
              <div className="user-search">
                <div className="user-search-back">
                  {/* <img src={search} alt="search" /> */}
                  <input type="text" placeholder="search" />
                </div>
              </div>
              <div style={{ overflowY: "scroll", height: "200px" }}>
                {userList.map((data) =>
                  data.name ? (
                    <div className="mt-3">
                      <label
                        className="chekboxstyle1 d-flex align-items-center justify-content-between bg-light rounded-3 px-3 py-2"
                        htmlFor="checkbox1"
                      >
                        <div className="d-flex flex-column gap-1">
                          <span
                            style={{
                              font: "normal normal normal 12px/30px Poppins",
                              color: "#000000",
                            }}
                          >
                            {data.name}
                          </span>
                          <span
                            style={{
                              font: "normal normal normal 12px/30px Poppins",
                              color: "#000000",
                            }}
                          >
                            {data.mobile}
                          </span>
                        </div>
                        {userIds.includes(data._id) ? (
                          <input
                            type="checkbox"
                            id="group1"
                            name="group1"
                            value={data._id}
                            checked={true}
                            // onChange={(e) => setArrayUserIds(e.target.value)}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            id="group1"
                            name="group1"
                            value={data._id}
                            checked={false}
                            // onChange={(e) => setArrayUserIds(e.target.value)}
                          />
                        )}
                      </label>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>

              <div className="text-center">
                <button
                  className="btn3"
                  onClick={() => {
                    closeModal();
                    
                  }}
                >
                  Add
                </button>
              </div>
            </Modal>
          </div>
        </div>
        <div>
         
        </div>
      </div>
            <div className="dash-first dash-common box-border-3 mb-4 m-5" id="add-faq">
                <div className="form-group mb-3">
                    <label className="mb-2">Topic</label>
                    <input
                        className="w-100 rounded-50 p-3"

                    />
                </div>
                <div className="form-group">
                    <label className="mb-2">Description</label>
                    <textarea
                        className="w-100 textarea-round p-3"
                        rows="6"


                    />
                </div>
                {/* <div className="text-end mt-3 flex justify-end gap-3">
                    <button
                        className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"

                    >
                        Cancel
                    </button>
                    <button
                        className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                        style={{ backgroundColor: "#434553" }}
                    >
                        Create
                    </button>
                </div> */}
            </div>
            <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}
            >
                <Modal.Body className="p-0 h-48" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3 ">
                            
                            <form className="custom_form" >
                            <h2>Select User</h2>
                                <div className="btn-wrapper text-center">
                                <label className="relative block">
                                        <span className="sr-only">Search</span>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <input
                                            className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                            placeholder="Search"
                                            type="text"
                                            name="Search"
                                            onClick={()=>openModal()}
                                        />
                                    </label>

                                    <div >
                                        <Form  >
                                            
                                            <div >
                                                <tr >
                                                <Form.Check
                                                inline
                                                label="Prince@gmail.com"
                                                name="group1"
                                            />
                                                </tr>
                                           <tr>
                                           <Form.Check
                                                inline
                                                label="rakesh@gmail.com"
                                                name="group1"
                                            />
                                           </tr>
                                            
                                           <tr>
                                           <Form.Check
                                                inline
                                                label="manish@gmail.com"
                                                name="group1"
                                            />
                                           </tr>
                                            </div>
                                            
                                        </Form>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default CreateNotification