import axios from "axios";

import { baseUrl } from "../config/base";

export const LoginAction = async (userObj) => {
  
  return await axios.post(`${baseUrl}/AdminUsers/VerifyUser`, userObj);
 
};

export const GenerateOTP = async (userObj) => {
  console.log(userObj,"Api Action")
  
  return await axios.post(`${baseUrl}/AdminUsers/GenerateOTP`, userObj);
 
};

export const VerifyOTP = async (userObj) => {
  console.log(userObj,"Api Action")
  
  return await axios.post(`${baseUrl}/AdminUsers/VerifyOTP`, userObj);
 
};
export const restpass = async (formData) => {
 
  var data= {"userId":formData.userId,
              "password":formData.password}
              console.log(formData,"Api Action")
  return await axios.put(`${baseUrl}/AdminUsers/UpdatePassword`, data);
 
};


///AdminUsers/UpdatePassword






