import React from "react";
import { Tab } from "react-bootstrap";
import ApprovedEvents from "./ApprovedEvents";
const EventManagement = ({ isNotification }) => {
  
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Existing">
                <Tab.Content className="mt-4">
                    <Tab.Pane eventKey="Existing">
                       
                        <ApprovedEvents  />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
};

export default EventManagement;
