import React, { useEffect, useState } from "react";
import { Row, Col, Dropdown, Modal, Form } from "react-bootstrap";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
import Table from 'react-bootstrap/Table';
import Spinnerr from "../../Components/Spinner/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import { getBonus, UpdateBonus } from "../../APIComponents/Bonus";
import Pagination from "../../Components/Pagination";
import { allUsersListAction ,allUsersListDate} from "../../APIComponents/UsersAction";
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import { DatePicker } from "antd";
import DropdownButton from 'react-bootstrap/DropdownButton';

const ApprovedEvents = ({ isNotification }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState();
    const [data, setData] = useState([]);
    const [welcome_bonus, setwelcome_bonus] = useState();
    const [referee_bonus, setreferee_bonus] = useState();
    const [referrer_bonus, setreferrer_bonus] = useState();
    const [bounsupdate, setbounsupdate] = useState(false);
    const bounsClose = () => setbounsupdate(false);
    const bounsShow = () => setbounsupdate(true);
    const [perPage, setPerPage] = useState(10);
    const [indexNumber, setIndexNumber] = useState(0);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: perPage,
        search: "",

    });
    const { RangePicker } = DatePicker;
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [starttDate, setStarttDate] = useState("");
    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [bounsdata, setbounsdata] = useState({
        "welcome_bonus": welcome_bonus,
        "referee_bonus": referee_bonus,
        "referrer_bonus": referrer_bonus


    })
    const filter = () => {
        let sendArray = []
        data.forEach((getUserData) => {
            console.log(getUserData, "MyNew Data")
            sendArray.push({
                ["User Id"]: getUserData.parentInfo.userId,
                ["Referee Name"]:getUserData.parentInfo.userId,
                ["User Id"]: getUserData.userId,
                ["Referrer Name"]: getUserData.userId,
                ["Referral Date"]: getUserData.createTime,
               
            })
        })
        exportToExcel(sendArray, "Refer Management")
    }
    const exportToExcel = (data, subject = "exportedData") => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, `${subject}.xlsx`);
    };
    const handleperpage = async (value) => {
        setLoading(true)
        setSearchQuery({ limit: value })
        setPerPage(value)
        console.log("data_searchQuery====>", searchQuery)
    }

    useEffect(() => {
        getBonus()
            .then((res => {
                console.log(res, "Data Bouns Function.................")
                setreferee_bonus(res.data.referee_bonus)
                setwelcome_bonus(res.data.welcome_bonus)
                setreferrer_bonus(res.data.referrer_bonus)
                if (res.status === 200) {
                    setLoading(false)
                }
                else {
                    setError(true)
                }
            }))

if(startDate==="",endDate===""){
    allUsersListAction(searchQuery)
    .then((res) => {
        console.log(res.data,"Main Data........UserList")
        if (res.data.status === true) {
            setResponse(res.data)
            setData(res.data.users)
            setTotalPages(Math.ceil(res.data.count / perPage))
            setLoading(false);
        }
        else if (res.data.status != true) {
            setError(true)
            setLoading(false)
        }
    })
}
else{
    allUsersListDate(startDate,endDate)
            .then((res) => {
                console.log(res.data,"Date Data.............Select Date......")
                if (res.data.status === true) {
                    setResponse(res.data)
                    setData(res.data.users)
                    setTotalPages(Math.ceil(res.data.count / perPage))
                    setLoading(false);
                }
                else if (res.data.status != true) {
                    setError(true)
                    setLoading(false)
                }
            })
}   
    }, [searchQuery,startDate,endDate])
    const handleupdate = () => {
        UpdateBonus(welcome_bonus, referee_bonus, referrer_bonus)
            .then((res=>{
                console.log(res.data.status)
                if(res.data.status===true){
                    bounsClose()
                    dispatch(showToast(`Bonus Updated`, "SUCCESS_TOAST"));
                }
                else if(res.data.status===false){
                     bounsClose()
                     dispatch(showToast(`Try Later.....`, "FAIL_TOAST"));}
            }))
    }
  
    const handleNoUpdate =()=>{
        getBonus()
            .then((res => {
                console.log(res, "Data")
                console.log(res.status)
                setreferee_bonus(res.data.referee_bonus)
                setwelcome_bonus(res.data.welcome_bonus)
                setreferrer_bonus(res.data.referrer_bonus)
            }))
        bounsClose()
    }


    const DashboardCard = ({ title, referal, bouns }) => (
        <div className=" col-sm-1 mb-4" style={{ height: "80px", width: "190px" }} onClick={() => bounsShow()}>
            <div className=" border-secondary rounded-lg py-2 px-2 h-100 " style={{ backgroundColor: "#f1f1f2", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <span>{title} </span>
                </div>
                <h3 style={{ fontWeight: "bold" }}>{referal}</h3>
                <h5 style={{ fontSize: "12px" }}>{bouns}</h5>
            </div>
        </div>
    );

    const paginationHandler = (e, i) => {
        e.preventDefault();
        console.log("iiii",i);
        if (i != page) {
          setPage(i);
          setIndexNumber(perPage * i - perPage);
          allUsersListAction({
            ...searchQuery,
            page: i,
            limit: perPage
          })
            .then((res) => {
                console.log(res.data)
                if (res.status === 200) {
                    setResponse(res.data)
                    setData(res.data.users)
                    setTotalPages(Math.ceil(res.data.count / perPage))
                    setLoading(false);
                }
                else if (res.data.status != true) {
                    setError(true)
                }
            })
        }
      };

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <div className="common-table-wrapper m-1">
                    <Row className="mb-0" >
                        <DashboardCard title={referee_bonus||"-"} referal={"Referee Bonus"} bouns={"For Each Referral"} />
                        <DashboardCard title={referrer_bonus||"-"} referal={"Referrer Bonus"} bouns={"For Each Referral"} />
                        <DashboardCard title={welcome_bonus||"-"} referal={"Joining Bonus"} />
                        <Col md={0} style={{ }}>
                            <div className="flex gap-2">
                                <div className="! gap-x-2 flex-col mb-3"> 
                                <label className="relative block">
                                    <span className="sr-only">Search</span>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                        <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </span>
                                    <input
                                        className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                        placeholder="Search"
                                        type="text"
                                        name="Search"
                                        value={searchQuery.search} // Bind the value of the input field to the search query
                                        onChange={(e) => setSearchQuery({ ...searchQuery, search: e.target.value, page: 1 })}
                                    />
                                </label>
                                <RangePicker className="! cursor-pointer   " style={{height:"45px",marginTop:"10px",background:"#e5e7eb",background:"#e5e7eb",borderRadius:"10px",border:"none"}} onChange={(e) => {
                                if (e) {
                                    
                                    setStartDate(moment(e[0].$d).format('YYYY-MM-DD'))
                                    setEndDate(moment(e[1].$d).format('YYYY-MM-DD'))
                                   
                                    
                                } else {
                                    setStartDate("")
                                    setEndDate("")
                                }
                                
                            }} />
                                </div>
                                <Link className="btn btn-form btn-cancel" onClick={() => { filter() }} >
                                Export
                            </Link>
                            </div>
                        </Col>

                    </Row>
                    <div className="table-responsive">
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>User Id</th>
                                    <th>Referee Name</th>
                                    
                                    <th>User Id</th>
                                    <th>Referrer Name</th>
                                    <th>Referral Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length > 0 ? (
                                    data.map(
                                        (
                                            {
                                                userId,
                                                userName,
                                                mobile,
                                                parentInfo,
                                                email,
                                                createTime

                                            },
                                            i,
                                        ) => (
                                            <tr key={i}>
                                                <td>{(page - 1) * 10 + i + 1}</td>
                                                
                                                {/* <td>{parentInfo.userId || "-"}</td> */}
                                                <td>{userId || "-"}</td>
                                                <td className="action-tab flex justify-center gap-2">
                                                    <Link className="inline-block" to={`/refer-management/refferals/${userName}`}>
                                                        {/* <span className="! font-bold text-blue-900">{parentInfo.userName ||"-"}</span> */}
                                                        <span className="! font-bold text-blue-900">{userName ||"-"}</span>
                                                    </Link>
                                                </td>
                                                <td>{userId || "-"}</td>
                                                <td>{userName || "-"}</td>
                                                
                                                
                                                <td>{createTime||"-"}</td>
                                            </tr>
                                        ),
                                    )
                                ) : (
                                    <td className="text-center" colSpan={13}>
                                        No Records Founds
                                    </td>
                                )}
                            </tbody>
                        </Table>
                    </div>

                    {data?.length > 0 ? (
                        <Row className="mt-4 px-2">

                                <Pagination 
                                    data={data}
                                    indexNumber={indexNumber}
                                    totalPage={totalPages}
                                    setPage={setPage}
                                    page={page}
                                    paginationHandler={paginationHandler}
                                />
                        </Row>
                    ) : (
                        ""
                    )}
                    <div >
<DropdownButton bsPrefix="p-2 px-4 border rounded-md bg-gray-800 text-white"   title="Page Limit">
<Form>
      {[ 'radio'].map((type) => (
        <div key={`default-${type}`} className="pl-5">
        <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`15`}
            onClick={()=>handleperpage(15)}
            
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`25 `}
            onClick={()=>handleperpage(25)}
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`50`}
            onClick={()=>handleperpage(50)}
          />

          
        </div>
      ))}
    </Form>
    </DropdownButton>

</div>
                </div>
            )}
            <Modal dialogClassName="modal-custom-sm" centered show={bounsupdate} onHide={bounsShow}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <div className="modelbox">
                                <label>Referee Bouns</label>
                                <input placeholder="Referee Bouns" className="myinput"
                                    value={referee_bonus}
                                    type="number"
                                    onChange={(e) => setreferee_bonus(e.target.value)}></input>
                                <label>Referrer Bouns</label>
                                <input placeholder="Referrer Bouns" className="myinput"
                                    type="number"
                                    value={referrer_bonus}
                                    onChange={(e) => setreferrer_bonus(e.target.value)}></input>

                                <label>Joining Bonus</label>
                                <input placeholder="Joining Bonus" className="myinput"
                                    value={welcome_bonus}
                                    type="number"
                                    onChange={(e) => setwelcome_bonus(e.target.value)}></input>

                            </div>
                            <form className="custom_form">
                                <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={() => handleNoUpdate()}>
                                        No
                                    </Link>
                                    <Link className="btn btn-form btn-add" onClick={()=>handleupdate()}>
                                        Update
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>

    );

};

export default ApprovedEvents;
