import {  useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
const Logs = ({ isNotification }) => {
    const {description,id}=useParams()
    return (
       
                <>
                <Col style={{textAlignLast:"end",marginBottom:20}}>
                    <Link
                       className="bg-gray-600 hover:bg-gray-800 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                        to={`/gameplay-management/Gamelogs/${id}`} >
                        Back
                    </Link>
                </Col>
                  <div className="dash-first dash-common" >
                        <div className="common-table-wrapper m-0">
                            <div className="table-responsive">
                                  <Table striped >
                                    <thead>
                                        <tr style={{textAlignLast:"start"}}>
                                            <th>Log</th>
                                        </tr> 
                                    </thead>
                                    <p style={{fontSize:15,padding:"30px"}}>{description}</p>
                                </Table>
                            </div>
                        </div>
                    </div>
              </> 
   )                                        
};

export default Logs;
