import axios from "axios";
import { toast } from "react-toastify";
import { HeaderToken } from "../Utils/HeaderToken";
import { baseUrl } from "../config/base";
// /Orders/GetOrdersReport?fromDate=20-04-2024&toDate=26-04-2024
export const paymentListAction = async (searchQuery) => {

  const myheader = {
    "userId": localStorage.getItem("userId"),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }
  const header = {
    headers: myheader
  }
  if (searchQuery.search) {
    https://api1.xtable.tv/api/Admin/Orders/GetOrders?pageNo=1&limitCount=10
    return await axios.get(`${baseUrl}/Orders/GetOrders?pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}&searchValue=${searchQuery.search}`, header);
  }
  else {
    return await axios.get(`${baseUrl}/Orders/GetOrders?pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}`, header);
  }


};
export const paymentListActionDate = async (startDate, endDate) => {

  const myheader = {
    "userId": localStorage.getItem("userId"),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }
  console.log(startDate, endDate, 'from api')
  const header = {
    headers: myheader
  }
  return await axios.get(`${baseUrl}/Orders/GetOrders?pageNo=${startDate}&limitCount=${endDate}`, header);



};



