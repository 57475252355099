import axios from "axios";

import { baseUrl } from "../config/base";
// https://api1.xtable.tv/api/Admin/GameUsers/GetUsersReport
export const allUsersListAction = async (searchQuery) => {
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  try {
    if (searchQuery.search) {
      return await axios.get(`${baseUrl}/GameUsers/GetUsers?pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}&searchValue=${searchQuery.search}`, HeaderToken);
    }

    else {
      return await axios.get(`${baseUrl}/GameUsers/GetUsers?pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}`, HeaderToken);
    }
  } catch (error) {

  }



};
export const allUsersListDate = async (startDate, enddate) => {
  console.log(startDate, enddate, "DAte With Api Action..............")
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  return await axios.get(`${baseUrl}/GameUsers/GetUsersReport?fromDate=${startDate}&toDate=${enddate}`, HeaderToken);
};
export const GetUserGameDetails = async (searchQuery) => {
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  return await axios.get(`${baseUrl}/GameHistory/GetGameHistoryById?gameUserId=${searchQuery.userId}&pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}`, HeaderToken);
};


export const deleteAction = async (userId) => {
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  return await axios.delete(`${baseUrl}/GameUsers/DeleteUser?userId=${userId}`, HeaderToken);
}

export const blockaction = async (userId) => {
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  return await axios.put(`${baseUrl}/GameUsers/BanUser`, userId, HeaderToken);
}

export const unblockaction = async (userId) => {
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  return await axios.put(`${baseUrl}/GameUsers/UnBanUser`, userId, HeaderToken);
}



export const getSingleUser = async (userId) => {
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  return await axios.get(`${baseUrl}/GameUsers/GetUserDetails?userId=${userId}`, HeaderToken);
  console.log(userId)

}






