import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/LogoXtable.png";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { GenerateOTP ,VerifyOTP} from "../../APIComponents/LoginAction";
import { useDispatch } from "react-redux";
import { showToast } from "../../Services/tostify";
import localStorage from "redux-persist/es/storage";
const IconCheck = require("../../assets/image/icon-check.png");
const OtpSend = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const email = location.pathname.split("/")[2];
    const navigate = useNavigate();
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  

    const handleSubmit = (e) => {
        e.preventDefault();

        const userObj={
            username:email  
        }
       GenerateOTP(userObj)
       .then((res=>{
        if(res.data.status===true){
                        
            console.log(res,"ReOtp Send")
            dispatch(showToast("OTP Send Successfully", "SUCCESS_TOAST"));
        }
        else if (res.data.status!=true)
        {
            console.log(res.data.error);
            dispatch(showToast(res?.data?.message, "FAIL_TOAST"));
        }
       }))
    };

    

    const handleInputChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value.length === 1 && index < 5) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    const verifyOtp = () => {
        const otpString = otp.join("");
        console.log("OTP:", otpString);
        const userObj = {
            username: email,
            otp: otpString,
        };
        VerifyOTP(userObj)
        .then((res=>{
            console.log(res)
            if(res.data.status==true)
            { 
                
            console.log(res.data.userId,"user id Get")
           
                dispatch(showToast("Otp Verified", "SUCCESS_TOAST"));
                navigate(`/reset-password/${res.data.userId}`);
            }
       
            else if(res.data.status!=true)
            {
            dispatch(showToast(res?.data?.message, "FAIL_TOAST"));
            }
        }))
        // checkOtp(userObj);
    };

    
    return (
        <>
            <div className="flex-center flex-column">
                <div>
                    <img className="" alt="logo" src={Logo} />
                </div>
                <div className="card login-wrapper border-0 mt-5">
                    <div className="card-header border-0 bg-transparent text-center">
                        <h4 className="text-xl font-bold">Otp Verification</h4>
                    </div>
                    <div className="card-body border-0 mt-3">
                        <form className="custom_form">
                            <div className="form-group">
                                <div className="d-flex gap-4">
                                    {[...Array(6)].map((_, index) => (
                                        <input
                                            key={index}
                                            type="number"
                                            value={otp[index]}
                                            className="inline-flex items-center justify-center w-25 h-25 py-3 text-center text-sm text-gray-900 bg-transparent rounded-xl outline-1 outline-double outline-gray-400 dark:text-dark dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                            id={`otp-${index}`}
                                            placeholder=""
                                            maxLength="1"
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <button
                                type="button"
                                onClick={verifyOtp}
                                style={{borderRadius:"30px",backgroundColor:"black"}}
                                className="text-white font-medium rounded-xl text-base px-5 py-3.5 text-center me-2 mb-2  block w-100"
                            >
                                Verify OTP
                            </button>
                        
                            <div className="form-group text-center">
                                <Link className="forgot-pass text-black" onClick={handleSubmit}>
                                    Resend OTP
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OtpSend;
