import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';

import moment from "moment";
import Pagination from "../../Components/Pagination";

import Spinnerr from "../../Components/Spinner/Spinner";
import axios from "axios";
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import { DatePicker } from "antd";
import { Table } from "react-bootstrap";

import { paymentListAction ,paymentListActionDate} from "../../APIComponents/PaymentAction";
const { RangePicker } = DatePicker;
const PaymentManagement = ({ isNotification }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setlimit] = useState(10); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState();
    const [nextPage, setNextPage] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [indexNumber, setIndexNumber] = useState(0);
    const [searchQuery, setSearchQuery] = useState({
        page: page,
        limit: perPage,
    });
    const [datesytem,setdatesystem]=useState({
        start:"",
        end:""
    })
    const filter = () => {
        let sendArray = []
        data.forEach((getUserData) => {
            console.log(getUserData, "MyNew Data")
            sendArray.push({
                ["Order Id"]: getUserData.txnId,

                ["Order Date"]: getUserData.userId,
                ["User Id"]: getUserData.userId,
                ["Pack Bought"]: "Pack Bought",
                ["Order Amount"]: getUserData.txnId,

                //   ["Date of Joining"]:dateFormatForDisplay(getUserData.isCreated),
                //   ["Name"]:getUserData.name,
                //   ["Mobile Number"]:getUserData.mobile,
                //   ["Country Code"]:getUserData.countryCode,
                //   ["Active Status"]:getUserData.isActive.toString()
            })
        })
        exportToExcel(sendArray, "Payment History")
    }


    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const exportToExcel = (data, subject = "exportedData") => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, `${subject}.xlsx`);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;

        setSearchQuery((prevQuery) => ({
            ...prevQuery,
            page: 1, // Reset page to 0 when searching
            search: value,
        }));
    };
    const paymentlist = () => {
        paymentListAction(searchQuery)
            .then((res) => {
                console.log(res)
                if (res.data.status === true) {
                    setData(res?.data?.data);
                    setTotalPages(Math.ceil(res.data.count / perPage))
                    setLoading(false)
                }
                else if (res.data.status === false) {
                    setError(true);
                    setLoading(false)
                }
            })
    }
    useEffect(() => {
        if(startDate==="",endDate===""){
            paymentlist()
        }
        else{ paymentListActionDate(startDate,endDate)
            .then((res)=>{
                console.log(res.data,"newapi datat")
                if (res.data.status === true) {
                    setData(res?.data?.data);
                    setTotalPages(Math.ceil(res.data.count / perPage))
                    setLoading(false)
                }
                else if (res.data.status === false) {
                    setError(true);
                    setLoading(false)
                }
            
            })}
       
        
        
    }, [searchQuery, perPage,startDate,endDate]);

   

    const handleperpage = async (value) => {
        setLoading(true)
        setSearchQuery({ limit: value })
        setPerPage(value)
        console.log("data_searchQuery====>", searchQuery)
    }


    const paginationHandler = (e, i) => {
        e.preventDefault();
        console.log("iiii", i);
        
        if (i != page) {
            setPage(i);
            setIndexNumber(perPage * i - perPage);
            paymentListAction({
                page: i,
                limit: perPage,
            })
                .then((res) => {
                    console.log(res)
                    if (res.data.status === true) {
                        setData(res?.data?.data);
                        setTotalPages(Math.ceil(res.data.count / perPage))
                        setLoading(false)
                    }
                    else if (res.data.status === false) {
                        setError(true);
                        setLoading(false)
                    }
                })
        }
    };console.log(startDate,"start")

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <>
                
                    <h5 style={{ paddingBottom: 10, paddingLeft: 10, fontWeight: "bold" }}>All Hosting Fees</h5>
                    <div className="dash-first dash-common">
                        <div className="common-table-wrapper m-0 flex justify-between">
                            <Row className="mb-1" style={{ justifyContent: "end" }}>
                                <Col md={0}>
                                    <div className="flex gap-3">
                                        <label className="relative block">
                                            <span className="sr-only">Search</span>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg className="h-5 w-5 fill-slate-500" viewBox="0 0 20 20">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <input
                                                className="placeholder:text-gray-400 block bg-gray-200 w-full border border-slate-300 rounded-xl py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base"
                                                placeholder="Search"
                                                type="text"
                                                name="Search"
                                                onChange={handleSearchChange}
                                            />
                                        </label>


                                    </div>
                                </Col>

                            </Row>

                            <RangePicker className="! cursor-pointer " style={{background:"#e5e7eb",borderRadius:"10px",border:"none"}} onChange={(e) => {

                                
                                
                                
                                if (e) {
                                    setStartDate(moment(e[0].$d).format('YYYY-MM-DD'))
                                    setEndDate(moment(e[1].$d).format('YYYY-MM-DD'))
                                   
                                    
                              
                                } else {
                                    setStartDate("")
                                    setEndDate("")

                                }
                            }} />
                            <Link className="btn btn-form btn-cancel" onClick={() => { filter() }} >
                                Export
                            </Link>
                        </div>

                    </div>
                    <div className="dash-first dash-common mt-3" >

                        <div className="common-table-wrapper m-0">

                            <div className="table-responsive">

                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Sr.No.</th>
                                            <th>Order Id</th>
                                            <th>Order Date</th>
                                            <th>User Id</th>
                                            <th>Pack Bought</th>
                                            <th>Order Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.length > 0 ? (


                                            data.map(({ userId, userName,orderId, orderTime, orderAmount,orderPlanName }, i) =>

                                            (<tr>
                                                <td>{indexNumber + i + 1}</td>
                                                <td>{orderId || "-"}</td>
                                                <td>{orderTime || "-"}</td>
                                                <td>{userId || "-"}</td>

                                                <td>{orderPlanName || "-"}</td>
                                                <td>{orderAmount || "-"}</td>
                                            
                                            </tr>
                                            ))
                                        ) : (
                                            <td className="text-center  " colSpan={13}>
                                                No Records Founds
                                            </td>
                                        )}
                                    </tbody>
                                </Table>  
                            </div>
                            
                            {data.length > 0 ? (
                                <> <Row className="mt-4 px-2  ">
                                    <Pagination
                                        data={data}
                                        indexNumber={indexNumber}
                                        totalPage={totalPages}
                                        setPage={setPage}
                                        page={page}
                                        paginationHandler={paginationHandler}
                                    />
<div >
<DropdownButton bsPrefix="p-2 px-4 border rounded-md bg-gray-800 text-white"   title="Page Limit">
<Form>
      {[ 'radio'].map((type) => (
        <div key={`default-${type}`} className="pl-5">
        <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`15`}
            onClick={()=>handleperpage(15)}
            
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`25 `}
            onClick={()=>handleperpage(25)}
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`50`}
            onClick={()=>handleperpage(50)}
          />

          
        </div>
      ))}
    </Form>
    </DropdownButton>

</div>
                                </Row>     
                                </>
                            ) : (
                                ""
                            )}

                        </div>
                    </div>
                </>
            )}
        </>
    )
};

export default PaymentManagement;
