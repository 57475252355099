import axios from "axios";

import { baseUrl } from "../config/base";

export const AllGameGet = async (searchQuery) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader}
  
  return await axios.get(`${baseUrl}/GameHistory/GetGameHistory?pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}`, header);
 
};

export const GameLogAction = async (searchQuery) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader}
  
  return await axios.get(`${baseUrl}/GameHistory/GetGameLogs?gameHistoryId=${searchQuery.id}&pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}`, header);
 
};
export const GamelogDate = async (startDate,enddate) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader}
  
  return await axios.get(`${baseUrl}/GameHistory/GetGameHistoryReport?fromDate=${startDate}&toDate=${enddate}`, header);
 
};



