import axios from "axios";

import { baseUrl } from "../config/base";
export const GetPrivacyPolicy = async () => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
  return await axios.get(`${baseUrl}/Settings/GetPrivacyPolicy`, header);
};
///////////////////////////////////////////////////////////////////////////
export const updatePrivacyPolicy = async (newData) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
var data= {"data":newData.terms_and_condition}
  return await axios.put(
    `${baseUrl}/Settings/UpdatePrivacyPolicy`,data
   ,
   header
  );
};
////////////////////////////////////////////////////////////////////////
export const GetTermAndCondition = async () => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
  return await axios.get(`${baseUrl}/Settings/GetTermsConditions`, header);
};
//////////////////////////////////////////////////////////////////////////////
export const updateTermAndCondition = async (newData) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
var data= {"data":newData.terms_and_condition}
  return await axios.put(
    `${baseUrl}/Settings/UpdateTermsConditions`,data
   ,
   header
  );
};
////////////////////////////////////////////////////////////////////////
export const getAboutus = async () => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
  return await axios.get(`${baseUrl}/Settings/GetAboutUs`, header);
};
//////////////////////////////////////////////////////////////////////////////
export const updateAboutUs = async (newData) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
var data= {"data":newData.terms_and_condition}
  return await axios.put(
    `${baseUrl}/Settings/UpdateAboutUs`,data
   ,
   header
  );
};
//////////////////////////////////////////////////////////////////////////////
export const GetFAQ = async () => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
  return await axios.get(`${baseUrl}/Settings/GetFAQs`, header);
};

export const updateFAQ = async (updatedEditFaq) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
console.log(updatedEditFaq,"Data From Edit")
  return await axios.put(
    `${baseUrl}/Settings/UpdateFAQs`,updatedEditFaq,
   header
  );
};

export const CreateFaq = async (body) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
  var data= {"question":body.question,
              "answer":body.answer}

  return await axios.post(`${baseUrl}/Settings/CreateFAQs`,data, header
  );
};


// /
export const DeleteFaq = async (deleteId) => {
 console.log(deleteId,"is coming from far")
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }

  return await axios.delete(`${baseUrl}/Settings/DeleteFAQs?id=${deleteId}`, header
  );
};

///////////////////////////////////////////////////////////////////////////////
export const GetRules = async () => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
  return await axios.get(`${baseUrl}/Settings/GetRules`, header);
};

export const updateRules = async (newData) => {
  const myheader={
    "userId":localStorage.getItem("userId"),
    "Authorization":"Bearer "+localStorage.getItem('token')
  }
  const header={
  headers:myheader
  }
var data= {"data":newData.terms_and_condition}
  return await axios.put(
    `${baseUrl}/Settings/UpdateRules`,data
   ,
   header
  );}
////////////////////////////////////////////////////////////////////////////////////


