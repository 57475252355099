
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Dummy from "../../assets/image/user-circle.svg";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import Card from 'react-bootstrap/Card';
function NofiticationView() {
    const nagivate=useNavigate()
    const [block, setBlock] = useState(false);
    const blockClose = () => setBlock(false);
    const blockShow = () => setBlock(true);
    return (
        <>

            <div>
                
                <Row style={{ justifyContent: "end" }}>

                <Col xl={1} className="text-end">
                <Link
                        className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 inline-flex items-center justify-center gap-1"
                        to={`/notification-management`} id="navigatortool"
                    >
                        Back
                    </Link>
                    </Col>
                
                    <Col xl={0} className="text-end">
                        <div
                            className="bg-gray-800 hover:bg-gray-600 text-white outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 d-inline-block"
                            id="navigatortool"
                            onClick={() => {nagivate("/notification-management")
                            
                            }}
                        >
                            Delete
                        </div>
                    </Col>
                    
                </Row>

            </div>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>    <Card style={{ width: '18rem', height: "15rem", borderRadius: 10, backgroundColor: "#f2f2f3", marginTop: "30px", borderStyle: "dashed" }}>
                <Card.Body>
                    
                    <div className="text-center" >
                        <div className="inline-flex flex-col justify-center items-center">
                            <img src={Dummy} alt="User" className="rounded-full" width="90" height="60" style={{marginTop:"60px"}}  />
                           
                        </div>
                    </div>


                </Card.Body>
            </Card>
               
            </div>
            <div className="dash-first dash-common box-border-3 mb-4 m-5" id="add-faq">
                <div className="form-group mb-3">
                    <label className="mb-2">Topic</label>
                    <input
                        className="w-100 rounded-50 p-3"

                    />
                </div>
                <div className="form-group">
                    <label className="mb-2">Description</label>
                    <textarea
                        className="w-100 textarea-round p-3"
                        rows="6"


                    />
                </div>
                
            </div>
           

        </>
    )
}

export default NofiticationView