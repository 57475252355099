import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/LogoXtable.png";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { restpass } from "../../APIComponents/LoginAction";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";


const IconCheck = require("../../assets/image/icon-check.png");

const ResetPassword = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const userId = location.pathname.split("/")[2];
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        userId: userId,
        password: "",
        confirmPassword: "",
    });
    const [show, setShow] = useState(false);
    const showPassoword = () => setShow(!show);
   

    const handleResetPassword = async () => {

        if (formData.password !== formData.confirmPassword) {
            dispatch(showToast("Password Does not match", "FAIL_TOAST"));
        } else {
            restpass(formData)
            .then((res=>{
                if(res.data.status===true){
                    navigate("/");
                    dispatch(showToast("Password Reset SuucessFully", "SUCCESS_TOAST"));
                }
                else if(res.data.status===false)
                {
                    dispatch(showToast(res?.data?.message, "FAIL_TOAST"));
                }
            }))
        }
    };
    return (
        <>
            <div className="flex-center flex-column">
                <div>
                    <img className="" alt="logo" src={Logo} />
                </div>
                <div className="card login-wrapper border-0 mt-5">
                    <div className="card-header border-0 bg-transparent text-center">
                        <h4 className="text-xl font-bold">Reset Password</h4>
                    </div>
                    <div className="card-body border-0 mt-3">
                        <form className="custom_form">
                            <div className="form-group">
                                <div class="relative">
                                    <input
                                        type={show ? "text" : "password"}
                                        className="block px-3 pb-2.5 pt-3 w-full text-sm text-gray-1000 bg-transparent rounded-xl outline-1 outline-double outline-gray-400 dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        id="pwd"
                                        name="password"
                                        placeholder="Password"
                                        style={{borderRadius:"30px"}}
                                        onChange={(e) => {
                                            setFormData({ ...formData, password: e.target.value });
                                        }}
                                    />
                                 
                                    <div className="pos-abs hide-show-pass" onClick={showPassoword}>
                                        {show ? <FaEye /> : <FaEyeSlash />}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div class="relative">
                                    <input
                                        type={show ? "text" : "password"}
                                        className="block px-3 pb-2.5 pt-3 w-full text-sm text-gray-1000 bg-transparent rounded-xl outline-1 outline-double outline-gray-400 dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        id="pwd"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        style={{borderRadius:"30px"}}
                                        onChange={(e) => {
                                            setFormData({ ...formData, confirmPassword: e.target.value });
                                        }}
                                    />
                                    <div className="pos-abs hide-show-pass" onClick={showPassoword}>
                                        {show ? <FaEye /> : <FaEyeSlash />}
                                    </div>
                                </div>
                            </div>

                            <Link
                                onClick={handleResetPassword}
                                className="text-white font-medium rounded-xl text-base px-5 py-3.5 text-center me-2 mb-2  block w-100"
                                style={{borderRadius:"30px",backgroundColor:"black"}}
                            >
                                Rest Password
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default ResetPassword;
