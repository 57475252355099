import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../../Services/tostify";
import { MdMode } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getAboutus,updateAboutUs } from "../../../APIComponents/cmsAction";
import Spinnerr from "../../../Components/Spinner/Spinner";
import JoditEditor from "jodit-react";
const Aboutus = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [newData, setNewData] = useState({
    terms_and_condition:"",
    
        
    });
    const [isEditing, setIsEditing] = useState(false);
   
    useEffect(() => {
        getAboutus()
        .then((res) => {
            console.log(res.data)
            setData(res.data.data)
            setNewData({ ...newData, terms_and_condition: res.data.data })
             if(res.status===200){
                 setLoading(false);
               }  
         })
    }, [])
    const handleSave = () => {
        console.log(newData.terms_and_condition)
        updateAboutUs(newData)
        .then((res=>{
          console.log(res)
            if(res.status===200){
                setLoading(false);
                getAboutus()
            .then((res) => {
                 if(res.status===200){
                    setData(res.data.data)
                   
                    setIsEditing(false)
                    dispatch(showToast("Privacy Policy Updated", "SUCCESS_TOAST")); 
                   }
             }) 
        } else if (!res===200) {
            setLoading(false);
            setError(true);
            dispatch(showToast("Data Not Updated", "FAIL_TOAST"));
        }            
        }))
    };
    // console.log(newData);
    
    return (
        
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : (
                <div className="dash-first dash-common box-border-3 mb-4">
                    <div className="card-body px-0">
                        {isEditing ? (
                            <>
                                <div className="card-head p-0 text-end gap-2 flex justify-end mb-4">
                                    <Link
                                        href="#"
                                        className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={() => setIsEditing(false)} // Cancel editing
                                    >
                                        Cancel
                                    </Link>
                                    <Link
                                        href="#"
                                        className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={handleSave} // Save changes
                                    >
                                        Save
                                    </Link>
                                </div>
                                <JoditEditor
                                 className="textji"
                                 value={newData.terms_and_condition}
                                 onChange={(text)=>setNewData({terms_and_condition:text})}
                                 />
                                {console.log(newData.terms_and_condition)}
                            </>
                        ) : (
                            <>
                                <div className="card-head p-0 text-end">
                                    <Link href="#" className="edit-icon" onClick={() => setIsEditing(true)}>
                                        <MdMode />
                                    </Link>
                                </div>
                                {/* <p className="textji">{data}</p> */}
                                <div className="textji cursor-not-allowed" dangerouslySetInnerHTML={{ __html: data }} />
                            </>
                        )}
                    </div>
                </div>
            )} 
        </>
    );
};

export default Aboutus;
