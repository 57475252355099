import axios from "axios";
import { HeaderToken } from "../Utils/HeaderToken";
import { baseUrl } from "../config/base";



export const getBonus = async (id) => {
  const myheader = {
    "userId": localStorage.getItem("userId"),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }
  const header = {
    headers: myheader
  }

  return await axios.get(`${baseUrl}/Settings/GetBonus`, header);
};





///////////////////////////////////////////////////////////////////////////////////
export const UpdateBonus = async (welcome_bonus, referee_bonus, referrer_bonus) => {
  const myheader = {
    "userId": localStorage.getItem("userId"),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }
  const header = {
    headers: myheader
  }
  const raw = ({
    "welcome_bonus": welcome_bonus,
    "referee_bonus": referee_bonus,
    "referrer_bonus": referrer_bonus
  });

  console.log(raw)
  return await axios.put(`${baseUrl}/Settings/UpdateBonus`, raw, header);
}


// fetch("https://api1.xtable.tv/api/Admin/Settings/UpdateBonus", requestOptions)
//   .then((response) => response.json())
//   .then((result) => console.log(result.status))
//   .catch((error) => console.error(error));
// };
////////////////////////////////////////////////////////////////////////////////////////







export const getRefferals = async (searchQuery) => {
  const myheader = {
    "userId": localStorage.getItem('userId'),
    "Authorization": "Bearer " + localStorage.getItem('token')
  }

  const HeaderToken = {
    headers: myheader
  }
  try {
    if (searchQuery.search) {
      return await axios.get(`${baseUrl}/GameUsers/GetUserReferee?userId=${searchQuery.userId}&pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}&searchValue=${searchQuery.limit}`, HeaderToken);
    } else {
      return await axios.get(`${baseUrl}/GameUsers/GetUserReferee?userId=${searchQuery.userId}&pageNo=${searchQuery.page}&limitCount=${searchQuery.limit}`, HeaderToken);
    }
  } catch (error) {

  }
}

