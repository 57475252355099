import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Spinnerr = () => {
  return (
    <>
      <div className="full-wrapper-spin">
        <Spinner animation="grow" size="lg" />
      </div>
    </>
  );
};

export default Spinnerr;
