import React, { useState } from "react";

import { Tab, Nav } from "react-bootstrap";
import OrganizserTnC from "./OrganizserTnC";


const TermsNCondition = () => {
    const [apiTrigger, setApiTrigger] = useState(0);

    const triggerApiCall = () => {
        // Increment the trigger value to force useEffect to re-run
        setApiTrigger((prevTrigger) => prevTrigger + 1);
    };
    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="organizer">
                <Tab.Content className="mt-4">
                    <Tab.Pane eventKey="organizer">
                        {/* Pass apiTrigger and triggerApiCall as props */}
                        <OrganizserTnC apiTrigger={apiTrigger} triggerApiCall={triggerApiCall} />
                    </Tab.Pane>
                   
                </Tab.Content>
            </Tab.Container>
        </>
    );
};
export default TermsNCondition;
