import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/LogoXtable.png";
import Modal from "react-bootstrap/Modal";
import { GenerateOTP } from "../../APIComponents/LoginAction";
import { showToast } from "../../Services/tostify";
import { useDispatch } from "react-redux";
const IconCheck = require("../../assets/image/icon-check.png");

const UpdatePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email,setemail]=useState()
    const handleSubmit = (e) => {
        e.preventDefault();
        const userObj={
            username:email
        }
       GenerateOTP(userObj)
       .then((res=>{
         
        if(res.data.status===true){
           console.log(res)
            VerificationShow();
            dispatch(showToast("OTP Send Successfully", "SUCCESS_TOAST"));
            
        }
        else if(res.data.status!=true){
            dispatch(showToast(res?.data?.message, "FAIL_TOAST"));
        }
        
       }))
    };
    const VerificationShow = () => {
        navigate(`/verify-otp/${email}`);
    }
    return (
        <>
            <div className="flex-center flex-column">
                <div>
                    <img className="" alt="logo" src={Logo} />
                </div>
                <div className="card login-wrapper border-0 mt-5">
                    <div className="card-header border-0 bg-transparent text-center">
                        <h4 className="text-xl font-bold">Forgot password?</h4>
                    </div>
                    <div className="card-body border-0 mt-3">
                        <form className="custom_form">
                            <div className="form-group mb-4">
                                <div className="relative pos-r">
                                    <input
                                        type="email"
                                        
                                        className="block px-3 pb-2.5 pt-3 w-full text-sm text-gray-1000 bg-transparent rounded-xl outline-1 outline-double outline-gray-400 dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer "
                                        id="email"
                                        onChange={(e)=>{setemail(e.target.value)}}
                                        placeholder="Registered Email*"
                                        style={{borderRadius:"30px",}}
                                    />
                                   
                                </div>
                            </div>
                            <button
                                className="text-white font-medium rounded-xl text-base px-5 py-3.5 text-center me-2 mb-2  block w-100"
                                onClick={(e) => handleSubmit(e)}
                                style={{borderRadius:"30px",backgroundColor:"black"}}
                            >
                                Send OTP
                            </button>

                            
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdatePassword;
