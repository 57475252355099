import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import TermsNCondition from "./TermsNCondition/TermsNCondition";
import Faqs from "./Faqs/Faqs";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Rules from "./TermsNCondition/Rules";
import Aboutus from "./TermsNCondition/About_us";

const CMS = ({ isNotification }) => {
    return (
        <>
            <Tabs defaultActiveKey="Aboutus" transition={true} id="noanim-tab-example" className="mb-3 nav-pills-tabs" variant="pills">
                <Tab eventKey="Aboutus" title="About Us">
                    <Aboutus />
                </Tab>
                <Tab eventKey="privacyPolicy" title="Privacy Policy">
                    <PrivacyPolicy />
                </Tab>
                <Tab eventKey="termConditional" title="Terms & Conditions">
                    <TermsNCondition isNotification={isNotification} />
                </Tab>

                <Tab eventKey="Rules" title="Rules">
                    <Rules />
                </Tab>
                <Tab eventKey="faqs" title="FAQs">
                    <Faqs />
                </Tab>
            </Tabs>
        </>
    );
};

export default CMS;
