import React, { useEffect, useState } from "react";
import View from "../../assets/image/view.svg";
import { Link, useParams } from "react-router-dom";
import { GameLogAction } from "../../APIComponents/GameLog";
import Pagination from "../../Components/Pagination";
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinnerr from "../../Components/Spinner/Spinner";
import { Table } from "react-bootstrap";
import moment from "moment";
import { DatePicker } from "antd";
import { Row, Col, Dropdown, Modal, Form } from "react-bootstrap";
const GameLogs = ({ isNotification }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1); // Initialize page state to start from 0
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [indexNumber, setIndexNumber] = useState(0);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: perPage,
    id: id,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { RangePicker } = DatePicker;
  const filter = () => {
    let sendArray = []
    data.forEach((getUserData) => {
        console.log(getUserData, "MyNew Data")
        sendArray.push({
            ["Timestamp"]: getUserData.timestamp,
            ["User Info"]:getUserData.userInfo,
            ["Description"]: getUserData.description,

        
        })
    })
    exportToExcel(sendArray, `Timestamp Log User${id}`)
}
const exportToExcel = (data, subject = "exportedData") => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, `${subject}.xlsx`);
};
const handleperpage = async (value) => {
  setLoading(true)
  setSearchQuery({ limit: value })
  setPerPage(value)
  console.log("data_searchQuery====>", searchQuery)
}


  useEffect(() => {
    GameLogAction(searchQuery).then((res) => {
      if (res.data.status === true) {
        console.log(res);
        setData(res.data.logs);
        setTotalPages(Math.ceil(res.data.count / perPage));
        setLoading(false);
      } else if (res.data.status != false) {
        setError(true);
        setLoading(false);
      }
    });
  }, []);

  const paginationHandler = (e, i) => {
    e.preventDefault();
    console.log("iiii", i);
    if (i != page) {
      setPage(i);
      setIndexNumber(perPage * i - perPage);
      GameLogAction({
        ...searchQuery,
        page: i,
        limit: perPage,
      }).then((res) => {
        if (res.data.status === true) {
          console.log(res);
          setData(res.data.logs);
          setTotalPages(Math.ceil(res.data.count / perPage));
          setLoading(false);
        } else if (res.data.status != false) {
          setError(true);
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      {loading ? (
        <Spinnerr />
      ) : error ? (
        "some error occured"
      ) : (
        <>
        <Col style={{}} className="! flex justify-between" >
                    <Link
                       className="bg-gray-600 hover:bg-gray-800   text-white outline-1 outline-double rounded-lg text-sm font-semibold px-5 py-2 d-inline-block"
                        to={`/gameplay-management`} >
                        Back
                    </Link>
                            <Link className="btn btn-form btn-cancel" onClick={() => { filter() }} >
                                Export
                            </Link>
                </Col>
          <h5
            style={{ paddingBottom: 10, paddingLeft: 10, fontWeight: "bold",marginTop:"10px" }}
          >
            All Logs
          </h5>
          
          <div className="dash-first dash-common">
            <div className="common-table-wrapper m-0">
              <div className="table-responsive">
                <Table striped>
                  <thead>
                    <tr style={{ textAlignLast: "start" }}>
                      <th>Timestamp </th>
                      <th>User Info </th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data.map(
                        (
                          { timestamp: timestamp, userInfo, description },
                          i
                        ) => (
                          <tr style={{ textAlignLast: "start" }}>
                            {/* <td>{(page - 1) * 10 + i + 1}</td> */}
                            <td>{timestamp}</td>
                            <td>{userInfo}</td>
                            <td>
                              {description?.substring(0, 80).concat("...")}
                              {/*  */}
                              
                            </td>
                            <td><Link
                                to={`/gameplay-management/Gamelogs/Logs/${description}/${id}`}
                              >
                               <img src={View} alt="" style={{height:26}}/>
                              </Link></td>
                          </tr>
                        )
                      )
                    ) : (
                      <td className="text-center" colSpan={13}>
                        No Records Founds
                      </td>
                    )}
                  </tbody>
                </Table>
              </div>
              <Row className="mt-4 px-2">
                <Pagination
                  data={data}
                  indexNumber={indexNumber}
                  totalPage={totalPages}
                  setPage={setPage}
                  page={page}
                  paginationHandler={paginationHandler}
                />
                     <div >
<DropdownButton bsPrefix="p-2 px-4 border rounded-md bg-gray-800 text-white"   title="Page Limit">
<Form>
      {[ 'radio'].map((type) => (
        <div key={`default-${type}`} className="pl-5">
        <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`15`}
            onClick={()=>handleperpage(15)}
            
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`25 `}
            onClick={()=>handleperpage(25)}
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`50`}
            onClick={()=>handleperpage(50)}
          />

          
        </div>
      ))}
    </Form>
    </DropdownButton>

</div>
              </Row>
              
            </div>
          </div>
        </>
      )}{" "}
    </>
  );
};

export default GameLogs;
