/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import Spinnerr from "../../../Components/Spinner/Spinner";
import { showToast } from "../../../Services/tostify";
import { CreateFaq, GetFAQ,DeleteFaq,updateFAQ } from "../../../APIComponents/cmsAction";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import JoditEditor from "jodit-react";
const UserFaqs = () => {
    const dispatch = useDispatch();
    const [faqData, setFaqData] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [newFaq, setNewFaq] = useState({ question: "", answer: "" });
    const [editFaq, setEditFaq] = useState({ id: "", question: "", answer: "" });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedFaqId, setSelectedFaqId] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
    const [deleteId, setDeleteId] = useState(null);
   const [question,setQuestion]=useState()
   const [answer,setAnswer]=useState()
    const body = {
        question: newFaq.question,
        answer: newFaq.answer
    };
    useEffect(() => {
        GetFAQ()
            .then((res => {
                if (res.data.status === true) {
                    console.log(res)
                    setFaqData(res.data.data)
                    setLoading(false);
                }
                else if (res.data.status != true) {
                    setError(true);
                    setLoading(false);
                }
            }))
    }, []);

   

       const handleEdit = async (id,question,answer) => {
        setSelectedFaqId(id);
        setShowEditForm(true);
        setEditFaq({ ...editFaq, answer:answer,question:question})
        console.log("My Data") 
    };

    const handleSaveEdit = async (updatedEditFaq) => {
        console.log(updatedEditFaq,"My Data")
        updateFAQ(updatedEditFaq)
        .then((res=>{
            if(res.data.status===true){
                setShowEditForm(false);
                dispatch(showToast("FAQ Updated", "SUCCESS_TOAST"));
                GetFAQ()
                .then((res=>{
                    setFaqData(res.data.data)
                }))
            }
            else if(res.data.status!=true){
                dispatch(showToast("FAQ Not Updated", "FAIL_TOAST"))
            }
        }))
    };

    const handleAddNew = () => {
        CreateFaq(body)
            .then((req => {
                if (req.data.status === true) {
                    console.log(req)
                    dispatch(showToast("FAQ Added", "SUCCESS_TOAST"));
                    GetFAQ()
                        .then(res => {
                            setFaqData(res.data.data)
                        })
                        setShowAddForm(false)    
                }
                else if(req.data.status != true){
                    setShowAddForm(false)
                    dispatch(showToast("FAQ Not Added Try Again Later", "FAIL_TOAST"))
                }
            }))

    };

    const handleDelete = async () => {
         await DeleteFaq(deleteId)
         
         .then((res=>{
            console.log(res)
            if(res.data.status===true){
                closeDeleteModal()
                dispatch(showToast("FAQ Deleted", "SUCCESS_TOAST"));
                GetFAQ()
            .then((res => {
                    setFaqData(res.data.data)
                    setLoading(false);
            }))
            }
            else if(res.data.status!=true){
                closeDeleteModal()
                dispatch(showToast("FAQ Not Deleted", "FAIL_TOAST"))

            }
         }))
    };

    const openDeleteModal = (id) => {
        setDeleteId(id);
        console.log(id,"id coming")
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "Some error occurred."
            ) : (
                <>
                    <div className="dash-first dash-common mb-4">
                        <div className="card-head p-0 text-end">
                            <button
                                className="text-sky-600 hover:text-sky-800 outline-1 outline-double rounded-full text-sm font-semibold px-4 py-2 d-inline-block"
                                onClick={() => setShowAddForm(true)}
                            >
                                + Add New
                            </button>
                        </div>
                        {showAddForm && (
                            <div className="dash-first dash-common box-border-3 mb-4" id="add-faq">

                                <div className="form-group mb-3">
                                    <label className="mb-2">Question</label>
                                    
                                    <textarea
                                            className="w-100 rounded-50 p-3"
                                            value={newFaq.question}
                                            onChange={(text)=>setNewFaq({...newFaq,question:text.target.value})}

                                            />
                                </div>
                                <div className="form-group">
                                    <label className="mb-2">Answer</label>
                                   
                                    <textarea
                                            className="w-100 rounded-50 p-3"
                                            value={newFaq.answer}
                                            onChange={(text)=>setNewFaq({...newFaq,answer:text.target.value})}

                                            />
                                </div>
                                <div className="text-end mt-3 flex justify-end gap-3">
                                    <button
                                        className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={() => setShowAddForm(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                        onClick={handleAddNew}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        )}
                        {faqData.map((faq) => ( 
                            <div key={faq.id} className="card-body faq-list box-border-3 py-3 mt-4" style={{justifyContent:"space-between"}}>
                              
                                {selectedFaqId === faq.id && showEditForm ? (
                                    <div className="dash-first dash-common box-border-3 mb-4 w-100" id="edit-faq">
                                        <div className="form-group mb-3">
                                            <label className="mb-2">Question</label>
                                            <textarea className="w-100 rounded-50 p-3"
                                            value={editFaq.question}
                                            onChange={(text)=>setEditFaq({...editFaq,question:text.target.value})}/>
                                           
                                           
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2">Answer</label>
                                            <textarea className="w-100 rounded-50 p-4"
                                            rows={10}
                                            value={editFaq.answer}
                                            onChange={(text)=>setEditFaq({...editFaq,answer:text.target.value})}/>
                                           
                                            
                                        </div>
                                        <div className="text-end mt-3 flex justify-end gap-3">
                                            <button
                                                className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                                onClick={() => setShowEditForm(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="text-white bg-red-600 hover:bg-red-800 outline-1 outline-double rounded-pill text-sm font-semibold px-4 py-2 inline-flex items-center justify-center"
                                                onClick={() => {
                                                    const updatedEditFaq = { ...editFaq, id: faq.id };
                                                    setEditFaq(updatedEditFaq);
                                                    handleSaveEdit(updatedEditFaq);
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                       <div >
                                        <label style={{fontWeight:"bold"}}>Question</label>
                                       <p className="mb-0 line-clamp" dangerouslySetInnerHTML={{ __html:faq.question  }}/>
                                       <label style={{fontWeight:"bold"}}>Answer</label>
                                        <p className="mb-0 line-clamp"style={{height:"max-content"}} dangerouslySetInnerHTML={{ __html:faq.answer  }}/>
                                       </div>

                                        <div className="d-flex">
                                            <button className="edit-icon me-2" onClick={() => handleEdit(faq.id,faq.question,faq.answer)}>
                                                <MdModeEdit />
                                            </button>
                                            <button className="edit-icon" onClick={() => openDeleteModal(faq.id)}>
                                                <MdDelete />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
            <Modal show={showDeleteModal} onHide={closeDeleteModal} size="sm" centered>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2 fw-bold">Are you sure you want to delete this FAQ?</p>
                            <div className="btn-wrapper text-center">
                                <Button variant="secondary" className="btn btn-form btn-cancel" onClick={closeDeleteModal}>
                                    No
                                </Button>
                                <Button variant="primary" className="btn btn-form btn-add ms-3" onClick={handleDelete}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserFaqs;
