import React, { Suspense, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import PaymentManagement from "./Pages/PaymentManagement/PaymentManagement";
import CMS from "./Pages/CMS/CMS";

import { ToastifyContainer } from "./Services/tostify";
import EventManagement from "./Pages/EventManagement/EventManagement";
import ResetPassword from "./Pages/Login/ResetPassword";
import CreateNotification from "./Pages/NotificationManagement/CreateNotification";
import Refferals from "./Pages/EventManagement/Refferals";
import GameplayManagement from "./Pages/GameplayManagement/GameplayManagement";
import NofiticationView from "./Pages/NotificationManagement/NofiticationView";
import OtpSend from "./Pages/Login/Otp-send";
import UpdatePassword from "./Pages/Login/UpdatePassword";
import GameLogs from "./Pages/GameplayManagement/GameLogs";
import Logs from "./Pages/GameplayManagement/Logs";
import LoginOtp from "./Pages/Login/LoginOtp";
const NotificationManagement = React.lazy(() => import("./Pages/NotificationManagement/NotificationManagement"));
const Layout = React.lazy(() => import("./Components/Layout/Layout"));
const Spinnerr = React.lazy(() => import("./Components/Spinner/Spinner"));
const Login = React.lazy(() => import("./Pages/Login/Login"));
const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const UserManagement = React.lazy(() => import("./Pages/UserManagement/UserManagement"));
const ViewUser = React.lazy(() => import("./Pages/UserManagement/ViewUser"));
const AuthLayout = React.lazy(() => import("./Components/Layout/AuthLayout"));
function App() {
    const [isNotification, setIsNotification] = useState("");
    console.log(isNotification);
    return (
        <>
            <Suspense fallback={<Spinnerr />}>
                <ToastifyContainer />
                <Routes>
                    <Route path="/" element={<AuthLayout />}>
                    <Route index element={<Login />} />
                        <Route path="/update-password" element={<UpdatePassword />} />
                        <Route path="/verify-otp/:id" element={<OtpSend />} />
                        <Route path="/reset-password/:id" element={<ResetPassword />} />
                        <Route path="/Login-otp/:email" element={<LoginOtp />} />

                    </Route>

                    <Route element={<Layout isNotification={isNotification} />}>
                        <Route index path="/dashboard" element={<Dashboard isNotification={setIsNotification} />} />
                        <Route path="/user-management" element={<UserManagement isNotification={setIsNotification} />} />
                        <Route path="/user-management/view-user/:userId" element={<ViewUser isNotification={setIsNotification} />} />
                        <Route path="/payment-management" element={<PaymentManagement isNotification={setIsNotification} />} />
                        <Route path="/refer-management" element={<EventManagement isNotification={setIsNotification} />} />
                        <Route path="/refer-management/refferals/:userId" element={<Refferals isNotification={setIsNotification} />} />
                        <Route path="/cms" element={<CMS isNotification={setIsNotification} />} /> 
                        <Route path="/notification-management" element={<NotificationManagement isNotification={setIsNotification} />} />
                        <Route path="/notification-management/create-notification" element={<CreateNotification isNotification={setIsNotification} />} />
                        <Route path="/notification-management/nofitication-view" element={<NofiticationView isNotification={setIsNotification} />} />
                     <Route path="/gameplay-management" element={<GameplayManagement isNotification={setIsNotification} />} />
                        <Route path="/gameplay-management/Gamelogs/:id" element={<GameLogs isNotification={setIsNotification} />} />
                        <Route path="/gameplay-management/Gamelogs/Logs/:description/:id" element={<Logs isNotification={setIsNotification} />} />
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default App;
