import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { AllGameGet,GamelogDate } from "../../APIComponents/GameLog";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Sort from "../../assets/image/sort-down-alt.svg";
import Filter from "../../assets/image/filterr.svg";
import Dummy from "../../assets/image/user-circle.svg";
import View from "../../assets/image/view.svg";
import Pagination from "../../Components/Pagination";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import moment from "moment";
import Spinnerr from "../../Components/Spinner/Spinner";
import { Table } from "react-bootstrap";
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import { DatePicker } from "antd";

const GameplayManagement = ({ isNotification }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1); // Initialize page state to start from 0
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [indexNumber, setIndexNumber] = useState(0);
    const [player,setPlayer]=useState([])
    const [unblock, setUnblock] = useState(false);
    const unblockClose = () => setUnblock(false);
    const unblockShow = () => setUnblock(true);
    const [searchQuery, setSearchQuery] = useState({
        page:1,
        limit: perPage,  
    });
    const { RangePicker } = DatePicker;
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const filter = () => {
        let sendArray = []
        data.forEach((getUserData) => {
            console.log(getUserData, "MyNew Data")
            sendArray.push({
                ["Game Host"]: getUserData.host.name,
                ["Game Name"]:getUserData.gameName,
                ["Player"]: getUserData.players,
                ["Created Date"]: getUserData.createTime,
            })
        })
        exportToExcel(sendArray, "Game Logs")
    }
    const handleperpage = async (value) => {
        setLoading(true)
        setSearchQuery({ limit: value })
        setPerPage(value)
        console.log("data_searchQuery====>", searchQuery)
    }

    const exportToExcel = (data, subject = "exportedData") => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, `${subject}.xlsx`);
    };
    useEffect(() => {
        if(startDate==="",endDate===""){
            AllGameGet(searchQuery)
            .then((res) => {
                if(res.data.status===true){
                    console.log(res.data.data,"response Main Api............")
                    setData(res.data.data)
                    setTotalPages(Math.ceil(res.data.count / perPage))
                    setLoading(false); 
                }
                else if(res.data.status!=true){
                    setError(true);
                    setLoading(false)  
                }
            })
        }
        else{
            GamelogDate(startDate,endDate)
        .then((res)=>{
            if(res.data.status===true){
                console.log(res,"Response data Api..........")
                setData(res.data.data)
                setTotalPages(Math.ceil(res.data.count / perPage))
                setLoading(false);
            }
            else if(res.data.status!=true){
                setError(true);
                setLoading(false) 
            }
        })
        }     
    }, [searchQuery,startDate,endDate]);
    const paginationHandler = (e, i) => {
        e.preventDefault();
        console.log("iiii",i);
        if (i != page) {
          setPage(i);
          setIndexNumber(perPage * i - perPage);
        AllGameGet({
            page:i,
            limit: perPage,  
        })
        .then((res) => {
            if(res.data.status===true){
                console.log(res.data.data)
                setData(res.data.data)
                setTotalPages(Math.ceil(res.data.count / perPage))
                setLoading(false);
               
            }
            else if(res.data.status!=true){
                setError(true);
                
            }
           
        })
        }
      };
      const handledata=(product)=>{
        setPlayer(product)
        unblockShow()
      
      }
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                "some error occured"
            ) : ( 
                
                <>
                 <h5 style={{paddingBottom:10,paddingLeft:10,fontWeight:"bold"}}>All Games</h5>
                <div className="! flex justify-between mb-2">
                <RangePicker className="! cursor-pointer py-2 rounded-md" style={{background:"#e5e7eb",borderRadius:"10px",border:"none"}} onChange={(e) => {
                                if (e) {
                                    setStartDate(moment(e[0].$d).format('YYYY-MM-DD'))
                                    setEndDate(moment(e[1].$d).format('YYYY-MM-DD'))

                                } else {
                                    setStartDate("")
                                    setEndDate("")

                                }
                            }} />
                            <Link className="btn btn-form btn-cancel" onClick={() => { filter() }} >
                                Export
                            </Link>
                </div>
                   
                    <div className="dash-first dash-common" >
                    
                        <div className="common-table-wrapper m-0">
                        
                            <div className="table-responsive">
                               
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>S.No  </th>
                                            <th>Game Host </th>
                                            <th>Game Name </th>
                                            <th>Player</th>
                                            <th>Created Time</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.length > 0 ? (
                                            data.map(
                                                
                                                (product, i) => (

                                                    <tr>
                                                        <td>{(page - 1) * 10 + i + 1}</td>
                                                        <td>{product.host.name}</td>
                                                        <td>
                                                            {product.gameName}
                                                        </td>
                                                        <td><Link onClick={()=>handledata(product.players)} style={{fontWeight:"bold",color:"blue"}} >{product.players.length}</Link>
                                                          </td>

                                                        <td>{product.createTime}</td>
                                                       
                                                        <td className="action-tab flex justify-center gap-2">
                                                            <Link
                                                                className="inline-block"
                                                                to={`/gameplay-management/Gamelogs/${product.id}`}
                                                            >
                                                                <img src={View} alt="" style={{height:26}}/>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ),
                                            )
                                        ) : (
                                            <td className="text-center" colSpan={13}>
                                                No Records Founds
                                            </td>
                                        )}
                                        
                           
                        </tbody>
                                    
                                </Table>
                            </div>
                                <Row className="mt-4 px-2">
                                <Pagination 
                                    data={data}
                                    indexNumber={indexNumber}
                                    totalPage={totalPages}
                                    setPage={setPage}
                                    page={page}
                                    paginationHandler={paginationHandler}
                                />
                                   
                                </Row>
                            
                        </div>
                        <div >
<DropdownButton bsPrefix="p-2 px-4 border rounded-md bg-gray-800 text-white"   title="Page Limit">
<Form>
      {[ 'radio'].map((type) => (
        <div key={`default-${type}`} className="pl-5">
        <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`15`}
            onClick={()=>handleperpage(15)}
            
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`25 `}
            onClick={()=>handleperpage(25)}
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`50`}
            onClick={()=>handleperpage(50)}
          />

          
        </div>
      ))}
    </Form>
    </DropdownButton>

</div>

                    </div>
                    <Modal dialogClassName="modal-custom-sm" centered show={unblock} onHide={unblockClose}>
                <Modal.Body className="p-0">
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-2">
                        <div className="common-table-wrapper m-0">
                        <div className="table-responsive">
                        <Table striped>
                                    <thead>
                                        <tr >
                                            <th>S.No  </th>
                                            <th>Player Name </th>
                                            <th>Player Id </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {player.map(
                                                
                                                (gamer, i) => (
                                                    <tr>
                                                    <td>{i+1}</td>
                                                    <td>{gamer.name||"-"}</td>
                                                    <td>{gamer.id||"-"}</td>
                                                    </tr>
                                                ))}

                                    </tbody>
                                    
                        </Table> 
                        </div>  
                        </div>             
                            
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
              </>)
         } </>
   )                                        
};

export default GameplayManagement;
