import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Dummy from "../../assets/image/user-circle.svg";
import Pagination from "../../Components/Pagination";
import View from "../../assets/image/view.svg";
import { MdBlock } from "react-icons/md";
import moment from "moment";
import { getSingleUser } from "../../APIComponents/UsersAction";
import Spinnerr from "../../Components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { showToast } from "../../Services/tostify";
import { getRefferals } from "../../APIComponents/Bonus";
import Card from 'react-bootstrap/Card';
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import { DatePicker } from "antd";
const Refferals = ({ isNotification }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const id = location.pathname.split("/")[3];
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
   
    const [status, setStatus] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [email,setEmail]=useState();
    const [name,setName]=useState()
    const [mobile,setMobile]=useState()
    const {userId}=useParams()
    const [perPage, setPerPage] = useState(10);
    const [indexNumber, setIndexNumber] = useState(0);
    const [searchQuery, setSearchQuery] = useState({
        page: 1,
        limit: perPage,
        userId: userId,
    });
    const { RangePicker } = DatePicker;
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const filter = () => {
        let sendArray = []
        data.forEach((getUserData) => {
            console.log(getUserData, "MyNew Data")
            sendArray.push({
                ["User Id"]: getUserData.userId,
                ["User Name"]:getUserData.userName,
                ["User Email"]: getUserData.email,

                ["Registration Date"]: getUserData.createTime,
                ["Last Hosted Game Date"]: getUserData.lastHostedGameTime,
                ["Last Played Game Date"]: getUserData.lastJoinedGameTime,
                ["Last Joined Date"]: "27-04-2024",
                ["Total Hosted Games"]: getUserData.totalHostedGames,
                ["Total Joined Games"]: getUserData.totalJoinedGames,
                ["Active Status"]:getUserData.isBanned.toString()
            })
        })
        exportToExcel(sendArray, `User Referals User_ ${userId}`)
    }
    const exportToExcel = (data, subject = "exportedData") => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, `${subject}.xlsx`);
    };

   

    useEffect(() => {
        
        getSingleUser(userId)
            .then((res) => {
                
                console.log(res.data,"userData")
                if(res.data.status===true){
                    setEmail(res.data.email)
                    setMobile(res.data.mobile)
                   
                }
                else if(res.data.status!=true){
                    setError(true)
                }
            })
       
    }, [ ]);

    useEffect(() => {
        getRefferals(searchQuery)
        .then((res=>{
            console.log(res,"userdata ref")
            if(res.data.status===true)
            { 
                setData(res.data.users)
                setTotalPages(Math.ceil(res.data.count / perPage))
            } 
            else if(res.data.status!=false){
                setLoading(true)
            }
        }))
     
    }, [])
    

    const paginationHandler = (e, i) => {
        e.preventDefault();
        console.log("iiii",i);
        if (i != page) {
          setPage(i);
          setIndexNumber(perPage * i - perPage);
          getRefferals({
            ...searchQuery,
            page: i,
            limit: perPage
          })
        .then((res=>{
            if(res.data.status===true)
            { 
                setData(res.data.users)
                setTotalPages(Math.ceil(res.data.count / perPage))
            } 
            else if(res.data.status!=false){
                setLoading(true)
            }
        }))
        }
      };


    return (
        <>
            <Row style={{}}>
                <Col xl={4}>
                    <Link
                        className="text-stone-600 hover:text-stone-800 outline-1 outline-double rounded-lg text-sm font-semibold px-4 py-2 inline-flex items-center justify-center gap-1"
                        to={`/refer-management`}
                    >
                        Back
                    </Link>
                </Col>
                <Col xl={4}>
                    <Card style={{ width: '22rem',borderRadius:10 ,backgroundColor:"#f2f2f3"}}>
                        <Card.Body>
                            <div className="text-center" >
                                <div className="inline-flex flex-col justify-center items-center">
                                    <img src={ Dummy} alt="User" className="rounded-full" width="48" height="48" />
                                    <h3 style={{fontWeight:"bold"}}>{name||"-"}</h3>
                                    
                                </div>
                            </div>
               
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",fontSize:"15px" }}>Email id : <span>{email||"-"}</span></div>

                        </Card.Body>
                    </Card>

                </Col>
               
            </Row>

            <div className="common-table-wrapper m-0 mt-5">
                <Row className="mb-1">
                    <Col md={12}>
                        <h6 className="text-base font-semibold mb-0">All Refferal</h6>
                    </Col>
                   
                </Row>
                <div className="!flex justify-between px-3 pb-4">
                    <RangePicker className="! cursor-pointer w-60  " style={{height:"45px",background:"#e5e7eb",borderRadius:"10px",border:"none"}} onChange={(e) => {
                                if (e) {
                                    setStartDate(moment(e[0].$d).format('YYYY-MM-DD'))
                                    setEndDate(moment(e[1].$d).format('YYYY-MM-DD'))
                                   

                                } else {
                                    setStartDate("")
                                    setEndDate("")
                                }
                            }} />
                            <Link className="btn btn-form btn-cancel" onClick={() => { filter() }} >
                                Export
                            </Link>
                    </div>
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th>Sr.No.</th>
                                <th>User Name</th>
                                <th>Date & Time</th>
                                
                                <th>
                                    Mobile Number
                                </th>
                               

                            </tr>
                        </thead>
                        <tbody>
                               
                                {data?.length > 0 ? (
                                            data?.map(
                                                (
                                                    {
                                                       
                                                        phone,
                                                        userName,
                                                        txnTime
                                                    
                                                    },
                                                    i,
                                                ) => (
                                                    
                                                    <tr>
                                                        <td>{ i + 1+"."}</td> {/* Updated calculation for index */}
                                                       <td>{userName||"-"}</td>
                                                       <td>{phone||"-"}</td>
                                                       <td>26</td>
                                                        
                                                        
                                                    </tr>
                                                ),
                                            )
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan={12}>
                                                    No data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                    </table>
                </div>

                {data?.length> 0 ? (
                                <Row className="mt-4 px-2"> 
                                     <Pagination 
                                    data={data}
                                    indexNumber={indexNumber}
                                    totalPage={totalPages}
                                    setPage={setPage}
                                    page={page}
                                    paginationHandler={paginationHandler}
                                />
                                </Row>
                            ) : ( "")}
            </div>
           
        </>
    );
};

export default Refferals;
